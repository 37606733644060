import { inject, Injectable } from '@angular/core';
import { BigGtmTracking, type DataLayerItem } from '@big-direkt/gtm-tracking';
import { WindowService } from '@big-direkt/utils/environment';
import { type FormHistoryItem } from '../models/form-history-item';
import { type FormPageTrackingEvent } from '../models/form-page-tracking-event';
import { type FormTrackingEvent } from '../models/form-tracking-event';
import { type InternalSearchEvent } from '../models/internal-search-event';
import { type ServiceToolTrackingEvent } from '../models/service-tool-tracking-event';
import { type NavigationTrackingEvent } from './../models/navigation-tracking-event';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
/**
 *
 * @param obj
 * @param key
 */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function getProperty<T, K extends keyof T>(obj: T, key: K): T[K] {
    return obj[key]; // Inferred type is T[K]
}

interface WindowModel extends Window {
    dataLayer: DataLayerItem[];
}

@Injectable({
    providedIn: 'root',
})
export class TrackingService {
    private readonly formHistoryMap = new Map<string, FormHistoryItem[]>();
    private readonly windowService = inject(WindowService);
    private readonly eventsCache: DataLayerItem[] = [];

    private bigGtmTrackingService?: BigGtmTracking;

    /**
     * Initializes the BigGtmTracking
     * @param dataLayer The property key name of the datalayer on the window object
     */
    public initialize(dataLayer = 'dataLayer'): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!dataLayer || !getProperty<any, any>(window, dataLayer) || typeof getProperty<any, any>(window, dataLayer).push !== 'function') {
            console.warn(`No datalayer with the key ${dataLayer} is attached to the window`);

            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.bigGtmTrackingService = new BigGtmTracking(this.windowService.document(), { dataLayer: getProperty<any, any>(window, dataLayer) || [] });

        this.bigGtmTrackingService.pushToDataLayer({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        });

        this.eventsCache.forEach((event: DataLayerItem) => this.bigGtmTrackingService?.pushToDataLayer(event));
    }

    /**
     * Clears DataLayer object on window
     */
    public resetDataLayer(): void {
        (window as unknown as WindowModel).dataLayer = [];
    }

    public trackEvent(event: DataLayerItem): void {
        if (this.bigGtmTrackingService) {
            this.bigGtmTrackingService.pushToDataLayer(event);
        } else {
            // not initialized yet. wait for initialization.
            this.eventsCache.push(event);
        }
    }

    /**
     * Track a form event on the data layer.
     * @param formTrackingEvent The form event data to track
     */
    public trackFormEvent(formTrackingEvent: FormTrackingEvent): void {
        const dataLayerItem: DataLayerItem = {
            event: 'form_events',
            eventCategory: 'Forms',
            eventAction: formTrackingEvent.eventAction ?? '',
            eventLabel: formTrackingEvent.eventLabel ?? '',
            value: formTrackingEvent.eventDetail ?? '', // eventDetail is mapped on value
            targetUrl: formTrackingEvent.targetUrl ?? '',
            moduleName: formTrackingEvent.moduleName ?? '',
            stepName: formTrackingEvent.stepName ?? '',
            targetStepName: formTrackingEvent.targetStepName ?? '',
            required: formTrackingEvent.required ?? '',
            triggerElement: formTrackingEvent.triggerElement ?? '',
            fieldName: formTrackingEvent.fieldName ?? '',
            historyIds: formTrackingEvent.historyIds ?? '',
            fileType: formTrackingEvent.fileType ?? '',
            fileSize: formTrackingEvent.fileSize ?? '',
            duration: formTrackingEvent.duration ?? '',
            entryType: formTrackingEvent.entryType ?? '',
            q: formTrackingEvent.q ?? '',
            cat: formTrackingEvent.cat ?? '',
            res: formTrackingEvent.res ?? '',
            src: formTrackingEvent.src ?? '',

        };

        this.trackEvent(dataLayerItem);
    }

    /**
     * Track a form page tracking event on the data layer.
     * @param formPageTrackingEvent The form page tracking event data to track
     */
    public trackFormPageEvent(formPageTrackingEvent: FormPageTrackingEvent): void {
        const dataLayerItem: DataLayerItem = {
            event: 'page',
            ...formPageTrackingEvent,
        };

        this.trackEvent(dataLayerItem);
    }

    /**
     * Initialize a form history for current form.
     * @param formId The unique form id
     */
    public initializeFormHistory(formId: string): void {
        this.formHistoryMap.set(formId, []);
    }

    /**
     * Register a history step of a given form field, but only once.
     * @param formId The unique form id
     * @param fieldName The field name that was touched
     * @param fieldId
     * @param fieldTitle
     * @param required
     */
    public registerFormHistoryStep(formId: string, fieldId: string, fieldTitle?: string, required?: boolean): void {
        const steps: FormHistoryItem[] | undefined = this.formHistoryMap.get(formId);

        if (!steps || steps.map((field: FormHistoryItem) => field.fieldId).includes(fieldId)) {
            return;
        }

        this.formHistoryMap.get(formId)?.push({
            fieldId,
            fieldTitle,
            required,
        });
    }

    /**
     * Prepares the form history steps as a trail.
     * @param formId
     * @returns The form history steps joined by ' > '
     */
    public getPreparedFormHistorySteps(formId: string): string | undefined {
        if (!this.formHistoryMap.has(formId)) {
            return undefined;
        }

        return this.formHistoryMap
            .get(formId)
            ?.map((field: FormHistoryItem) => field.fieldId)
            .join(' > ');
    }

    /**
     * Prepares the form history step labels as a trail.
     * @param formId
     * @returns The form history step labels joined by ' > '
     */
    public getPreparedFormHistoryStepLabels(formId: string): string | undefined {
        if (!this.formHistoryMap.has(formId)) {
            return undefined;
        }

        return this.formHistoryMap
            .get(formId)
            ?.map((field: FormHistoryItem) => field.fieldTitle ?? field.fieldId)
            .join(' > ');
    }

    /**
     * Get the last registered form history step
     * @param formId
     * @returns The last form history step. Undefined for no registered steps
     */
    public getLastFormHistoryStep(formId: string): FormHistoryItem | undefined {
        const steps: FormHistoryItem[] | undefined = this.formHistoryMap.get(formId);

        if (!steps || steps.length === 0) {
            return undefined;
        }

        return steps[steps.length - 1];
    }

    /**
     * Track a navigation event on the data layer when clicking an 'a href' element.
     * @param navigationTrackingEvent The navigation event data to track
     */
    public trackNavigationEvent(navigationTrackingEvent: NavigationTrackingEvent): void {
        const dataLayerItem: DataLayerItem = {
            event: 'navigation',
            eventCategory: 'Navigation',
            eventAction: navigationTrackingEvent.eventAction ?? '',
            eventLabel: navigationTrackingEvent.eventLabel ?? '',
            targetUrl: navigationTrackingEvent.targetUrl ?? '',
            xPath: navigationTrackingEvent.xPath ?? '',
        };

        this.trackEvent(dataLayerItem);
    }

    /**
     * Track a internal search event on the data layer.
     * Only submit properties that are defined and not an empty string as fallback
     * @param internalSearchEvent The internal search event data to track
     */
    public trackInternalSearchEvent(internalSearchEvent: InternalSearchEvent): void {
        const dataLayerItem: DataLayerItem = {
            event: 'internal_search',
            eventAction: internalSearchEvent.eventAction,
            ...internalSearchEvent.entryType && { entryType: internalSearchEvent.entryType },
            ...internalSearchEvent.q && { q: internalSearchEvent.q },
            ...internalSearchEvent.cat && { cat: internalSearchEvent.cat },
            ...internalSearchEvent.res && { res: internalSearchEvent.res },
            ...internalSearchEvent.src && { src: internalSearchEvent.src },
            ...internalSearchEvent.clickRank && { iseClickRank: internalSearchEvent.clickRank },
            ...internalSearchEvent.clickTarget && { iseClickTarget: internalSearchEvent.clickTarget },
            ...internalSearchEvent.page && { pag: internalSearchEvent.page },
            ...internalSearchEvent.clickLabel && { clickLabel: internalSearchEvent.clickLabel },
        };

        this.trackEvent(dataLayerItem);
    }

    /**
     * Calculates the absolut XPath of any Dom Element
     * @param element The Dom Element
     * @returns The absolut XPath for the given Dom Element as string
     */
    // eslint-disable-next-line sonarjs/cognitive-complexity
    public getXpathForElement(element: Element | null): string {
        let nodeElem: Element | null = element;
        const parts: string[] = [];
        while (nodeElem && Node.ELEMENT_NODE === nodeElem.nodeType) {
            let nbOfPreviousSiblings = 0;
            let hasNextSiblings = false;
            let sibling: ChildNode | null = nodeElem.previousSibling;
            while (sibling) {
                if (sibling.nodeType !== Node.DOCUMENT_TYPE_NODE && sibling.nodeName === nodeElem.nodeName) {
                    nbOfPreviousSiblings = nbOfPreviousSiblings + 1;
                }
                sibling = sibling.previousSibling;
            }
            sibling = nodeElem.nextSibling;
            while (sibling) {
                if (sibling.nodeName === nodeElem.nodeName) {
                    hasNextSiblings = true;
                    break;
                }
                sibling = sibling.nextSibling;
            }
            const prefix: string = nodeElem.prefix ? `${nodeElem.prefix}:` : '';
            const nth: string = nbOfPreviousSiblings || hasNextSiblings ? `[${nbOfPreviousSiblings + 1}]` : '';
            parts.push(prefix + nodeElem.localName + nth);
            nodeElem = nodeElem.parentNode as Element;
        }

        // eslint-disable-next-line sonarjs/no-misleading-array-reverse
        return parts.length ? `/${parts.reverse().join('/')}` : '';
    }

    /**
     * Calculates the eventAction for urls
     * @param url The target url
     * @returns The GtmEventAction value for navigation events
     */
    public getTargetLinkType(url: string): 'external_link' | 'internal_link' | 'related_link' {
        if (url.startsWith('/') || url.startsWith(location.origin)) {
            return 'internal_link';
        }

        if (url.includes('.big-direkt.de')) {
            return 'related_link';
        }

        return 'external_link';
    }

    /**
     * Gets the text of an HTMLElement
     * @param element The clicked HTMLElement
     * @returns The either the responding data-title attribute or the text of the HTMLElement
     */
    public getPageNavigationEventLabel(element: HTMLElement): string | null {
        if (element.parentElement?.tagName === 'SVG') {
            return element.parentElement.parentElement?.getAttribute('data-title') ?? 'unknown';
        }

        if (element.parentElement?.tagName === 'BIG-ICON') {
            return element.parentElement.getAttribute('data-title') ?? 'unknown';
        }

        return element.innerText || element.textContent;
    }

    /**
     * Track a service tool event on the data layer.
     * @param serviceToolTrackingEvent The form event data to track
     */
    // eslint-disable-next-line sonarjs/cognitive-complexity
    public trackServiceToolEvent(serviceToolTrackingEvent: ServiceToolTrackingEvent): void {
        const dataLayerItem: DataLayerItem = {
            event: 'service_tool',
            action: serviceToolTrackingEvent.eventAction ?? '',
            id: serviceToolTrackingEvent.id ?? '',
            name: serviceToolTrackingEvent.name ?? '',
            state: serviceToolTrackingEvent.state ?? '',
            clickLabel: serviceToolTrackingEvent.clickLabel ?? '',
            resultNumber: serviceToolTrackingEvent.resultNumber ?? '',
            insuredPerson: serviceToolTrackingEvent.insuredPerson ?? '',
            fieldName: serviceToolTrackingEvent.fieldName ?? '',
            fieldType: serviceToolTrackingEvent.triggerElement ?? '',
            fieldDuration: serviceToolTrackingEvent.duration ?? '',
            fieldSelection: serviceToolTrackingEvent.fieldSelection ?? '',
            errorFields: serviceToolTrackingEvent.errorFields ?? '',
            popupDuration: serviceToolTrackingEvent.popupDuration ?? '',
            resultClickType: serviceToolTrackingEvent.resultClickType ?? '',
            submitState: serviceToolTrackingEvent.submitState ?? '',
            totalBenefits: serviceToolTrackingEvent.totalBenefits ?? '',
            ...serviceToolTrackingEvent.checkedBenefits ?? '',
        };

        this.trackEvent(dataLayerItem);
    }
}
